import { Location } from '@angular/common'
import { Component } from '@angular/core'
import { AuthService } from './auth/services/auth.service'
import { UserClient } from './common/clients/user.client'
import { UserSessionService } from './common/services/user-session.service'
import { UserService } from './common/services/user.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isUserLoaded = false
  public showNavbar = false
  public isPrintRoute = false

  constructor(
    private authService: AuthService,
    private userClient: UserClient,
    private userSession: UserSessionService,
    private userService: UserService,
    private location: Location,
  ) {
    this.isPrintRoute = this.location.path().includes('print')

    this.authService.isLoggedIn$.subscribe(async (isLoggedIn) => {
      if (isLoggedIn) {
        if (!this.userService.currentUser) {
          const user = await this.userClient.getCurrentUser()
          if (user.err) return
          this.userService.setCurrentUser(user.safeUnwrap())
        }
        this.userSession.start()
      }

      if (!this.isPrintRoute) this.showNavbar = isLoggedIn
    })

    this.userService.currentUser$.subscribe((user) => {
      this.isUserLoaded = !!user
    })
  }
}
