<ng-container *ngIf="show">
  <div
    class="sm:hidden bg-white drop-shadow-md fixed w-full h-screen"
    id="mobile-menu"
    *transloco="let t"
  >
    <div class="space-y-1 pb-3 pt-2">
      <a
        href="#"
        [routerLink]="['/schools']"
        routerLinkActive="border-red-primary text-red-primary bg-red-50"
        #schoolList="routerLinkActive"
        [ngClass]="{
          'border-transparent text-gray-unselected': !schoolList.isActive
        }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        (click)="onNavigate()"
        *ngIf="hasRole([UserRole.ACCOUNT_ADMIN])"
      >
        {{ t('navbar.schools') }}
      </a>
      <a
        href="#"
        [routerLink]="['/team-members']"
        routerLinkActive="border-red-primary text-red-primary bg-red-50"
        #teamMembers="routerLinkActive"
        [ngClass]="{
          'border-transparent text-gray-unselected': !teamMembers.isActive
        }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        (click)="onNavigate()"
        *ngIf="hasRole([UserRole.ACCOUNT_ADMIN])"
      >
        {{ t('navbar.team-members') }}
      </a>
      <a
        href="#"
        [routerLink]="['/students']"
        routerLinkActive="border-red-primary text-red-primary bg-red-50"
        #students="routerLinkActive"
        [ngClass]="{
          'border-transparent text-gray-unselected': !students.isActive
        }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        (click)="onNavigate()"
      >
        {{ t('navbar.students') }}
      </a>
      <a
        href="#"
        [routerLink]="['/lessons']"
        routerLinkActive="border-red-primary text-red-primary bg-red-50"
        #lessons="routerLinkActive"
        [ngClass]="{
          'border-transparent text-gray-unselected': !lessons.isActive
        }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        (click)="onNavigate()"
      >
        {{ t('navbar.lessons') }}
      </a>
      <a
        href="#"
        [routerLink]="['/reports']"
        routerLinkActive="border-red-primary text-red-primary bg-red-50"
        #reports="routerLinkActive"
        [ngClass]="{
          'border-transparent text-gray-unselected': !reports.isActive
        }"
        class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        (click)="onNavigate()"
      >
        {{ t('navbar.reports') }}
      </a>
    </div>
    <div class="border-t border-gray-200 pb-3 pt-4">
      <div class="flex items-center px-4">
        <div class="flex-shrink-0">
          <app-avatar class="h-10 w-10 block" [user]="currentUser"></app-avatar>
        </div>
        <div class="ml-3">
          <div class="text-base font-medium text-gray-800">
            {{ currentUser?.firstName }} {{ currentUser?.lastName }}
          </div>
          <div class="text-sm font-medium text-gray-500">
            {{ currentUser?.email }}
          </div>
        </div>
      </div>
      <div class="mt-3 space-y-1">
        <button
          type="button"
          class="flex px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full justify-start"
          (click)="openProfileForm()"
        >
          {{ t('navbar.profile') }}
        </button>
        <button
          type="button"
          class="flex px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full justify-start"
          (click)="onNavigate()"
        >
          {{ t('navbar.settings') }}
        </button>
        <button
          type="button"
          class="flex px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full justify-start"
          (click)="onSignOut()"
        >
          {{ t('navbar.sign-out') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
