<div
  class="flex items-center"
  [ngClass]="passwordFitRequirement() ? 'text-green-success' : 'text-gray-400'"
  *transloco="let t"
>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 0.827148C3.31967 0.827148 0.333008 3.81382 0.333008 7.49382C0.333008 11.1738 3.31967 14.1605 6.99967 14.1605C10.6797 14.1605 13.6663 11.1738 13.6663 7.49382C13.6663 3.81382 10.6797 0.827148 6.99967 0.827148ZM5.66634 10.8271L2.33301 7.49382L3.27301 6.55382L5.66634 8.94048L10.7263 3.88048L11.6663 4.82715L5.66634 10.8271Z"
    />
  </svg>
  <span class="ml-2 text-xs font-normal text-gray-500">{{
    t('password-field.' + requirement)
  }}</span>
</div>
