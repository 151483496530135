import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'
import {
  PASSWORD_REGEX_LOWERCASE,
  PASSWORD_REGEX_NUMBER,
  PASSWORD_REGEX_SPECIAL,
  PASSWORD_REGEX_UPPERCASE,
} from '../../constants'

@Component({
  selector: 'app-password-checklist-item',
  standalone: true,
  templateUrl: './password-checklist-item.component.html',
  styleUrls: ['./password-checklist-item.component.scss'],
  imports: [CommonModule, TranslocoModule],
})
export class PasswordChecklistItemComponent {
  @Input()
  public value!: string

  @Input()
  public requirement!: string

  public passwordFitRequirement(): boolean {
    switch (this.requirement) {
      case 'min-length':
        return Boolean(this.value.length > 7)
      case 'uppercase':
        return this.isValid(PASSWORD_REGEX_UPPERCASE)
      case 'lowercase':
        return this.isValid(PASSWORD_REGEX_LOWERCASE)
      case 'number':
        return this.isValid(PASSWORD_REGEX_NUMBER)
      case 'special-character':
        return this.isValid(PASSWORD_REGEX_SPECIAL)
      default:
        return false
    }
  }

  private isValid(requirement: RegExp): boolean {
    return Boolean(requirement.test(this.value))
  }
}
