<nav class="bg-white drop-shadow-md fixed top-0 z-10 w-full">
  <div class="mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex h-16 justify-between" *transloco="let t">
      <div class="flex">
        <button
          class="flex flex-shrink-0 items-center cursor-pointer"
          (click)="goToHomeRoute()"
        >
          <img
            class="h-8 w-auto hidden lg:block"
            src="../../../../assets/images/logo-with-name.svg"
            alt="Functional Academics"
          />

          <img
            class="h-8 w-auto block lg:hidden"
            src="../../../../assets/images/airplane-logo.svg"
            alt="Functional Academics"
          />
        </button>
        <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
          <a
            href="#"
            [routerLink]="['/schools']"
            routerLinkActive="border-red-primary text-red-primary"
            #schoolList="routerLinkActive"
            [ngClass]="{
              'border-transparent text-gray-unselected': !schoolList.isActive
            }"
            class="inline-flex items-center border-b-2 placeholder:border-b-2 px-1 pt-1 text-base font-headline font-medium hover:border-gray-300 hover:text-gray-700"
            *ngIf="hasRole([UserRole.ACCOUNT_ADMIN])"
          >
            {{ t('navbar.schools') }}
          </a>
          <a
            href="#"
            [routerLink]="['/team-members']"
            routerLinkActive="border-red-primary text-red-primary"
            #teamMembers="routerLinkActive"
            [ngClass]="{
              'border-transparent text-gray-unselected': !teamMembers.isActive
            }"
            class="inline-flex items-center border-b-2 px-1 pt-1 text-base font-headline font-medium text-gray-unselected hover:border-gray-300 hover:text-gray-700"
            *ngIf="hasRole([UserRole.ACCOUNT_ADMIN])"
          >
            {{ t('navbar.team-members') }}
          </a>
          <a
            href="#"
            [routerLink]="['/students']"
            routerLinkActive="border-red-primary text-red-primary"
            #students="routerLinkActive"
            [ngClass]="{
              'border-transparent text-gray-unselected': !students.isActive
            }"
            class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-headline font-medium text-gray-unselected hover:border-gray-300 hover:text-gray-700"
          >
            {{ t('navbar.students') }}
          </a>
          <a
            href="#"
            [routerLink]="['/lessons']"
            routerLinkActive="border-red-primary text-red-primary"
            #lessons="routerLinkActive"
            [ngClass]="{
              'border-transparent text-gray-unselected': !lessons.isActive
            }"
            class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-headline font-medium text-gray-unselected hover:border-gray-300 hover:text-gray-700"
          >
            {{ t('navbar.lessons') }}
          </a>
          <a
            href="#"
            [routerLink]="['/reports']"
            routerLinkActive="border-red-primary text-red-primary"
            #reports="routerLinkActive"
            [ngClass]="{
              'border-transparent text-gray-unselected': !reports.isActive
            }"
            class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-headline font-medium text-gray-unselected hover:border-gray-300 hover:text-gray-700"
          >
            {{ t('navbar.reports') }}
          </a>
        </div>
      </div>
      <div class="hidden sm:ml-6 sm:flex sm:items-center">
        <a
          href="https://forms.zohopublic.com/info3090/form/FunctionalAcademicsBetaTestFeedback/formperma/qmVh2SpZhfLCMsYBq8zQu7Jc5JM75FbQlWb-pAWQX9E"
          class="bg-[#1DB6D6] rounded-md text-white font-headline text-sm px-4 py-2 mr-2 inline-flex items-center justify-center"
          target="_blank"
          title="External Feedback Form - leaving Functional Academics logged in area"
          (click)="analyticsService.track({ name: 'feedback_button_clicked' })"
        >
          <span class="material-icons mr-2"> feedback </span>
          Feedback
        </a>
        <a href="https://functionalacademics.net"
          class="bg-[#1DB6D6] rounded-md text-white font-headline text-sm px-4 py-2 mr-2 inline-flex items-center justify-center"
          target="_blank" (click)="analyticsService.track({ name: 'resources_button_clicked' })"
          title="External Resources - leaving Functional Academics logged in area"
        >
          <span class="material-icons mr-2"> folder </span>
          Resources
        </a>
        <!-- Profile dropdown -->
        <div class="relative ml-3">
          <div>
            <button
              type="button"
              class="relative flex rounded-full bg-white text-sm focus:outline-none"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
              (click)="toggleProfileMenu()"
              (clickOutside)="closeProfileMenu()"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">Open user menu</span>

              <app-avatar
                class="h-8 w-8 block"
                [user]="currentUser"
              ></app-avatar>
            </button>
          </div>

          <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-200"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div
            *ngIf="showProfileMenu"
            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <button
              class="flex px-4 py-2 text-sm text-gray-700 w-full justify-start"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-0"
              (click)="openProfileForm()"
            >
              {{ t('navbar.profile') }}
            </button>
            <button
              class="flex px-4 py-2 text-sm text-gray-700 w-full justify-start"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-1"
            >
              {{ t('navbar.settings') }}
            </button>
            <button
              class="flex px-4 py-2 text-sm text-gray-700 w-full justify-start"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              (click)="signOut()"
            >
              {{ t('navbar.sign-out') }}
            </button>
          </div>
        </div>
      </div>
      <div class="-mr-2 flex items-center sm:hidden">
        <!-- Mobile menu button -->
        <button
          type="button"
          class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none"
          aria-controls="mobile-menu"
          aria-expanded="false"
          (click)="toggleMobileMenu()"
        >
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <!-- Icon when menu is closed. -->
          <span class="material-icons h-6 w-6" *ngIf="!showMobileMenu">
            menu
          </span>
          <!-- Icon when menu is open -->
          <span class="material-icons h-6 w-6" *ngIf="showMobileMenu">
            close
          </span>
        </button>
      </div>
    </div>
  </div>
  <app-mobile-menu
    *ngIf="currentUser"
    [show]="showMobileMenu"
    [currentUser]="currentUser"
    (navigated)="toggleMobileMenu()"
    (signedOut)="signOut()"
  ></app-mobile-menu>
</nav>
