import { Component, OnDestroy } from '@angular/core'
import { NotificationService } from './notification.service'
import { Notification, NotificationType } from './notification.model'
import { Subscription } from 'rxjs'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnDestroy {
  public notifications: Notification[] = []
  private subscription: Subscription

  constructor(notificationService: NotificationService) {
    this.subscription = notificationService
      .getObservable()
      .subscribe((notification) => this.addNotification(notification))
  }

  private addNotification(notification: Notification): void {
    this.notifications.push(notification)

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout)
    }
  }

  public get NotificationType(): typeof NotificationType {
    return NotificationType
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public close(notification: Notification): void {
    this.notifications = this.notifications.filter(
      (notif) => notif.id !== notification.id,
    )
  }
}
