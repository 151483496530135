import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { AnalyticsService } from '@uptechworks/analytics-service-angular'
import { CodeInputModule } from 'angular-code-input'
import { ColorPickerModule } from 'ngx-color-picker'
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper'
import { Subscription } from 'rxjs'
import { Err, Result } from 'ts-results'
import { AuthClient } from '../../auth/services/auth.client'
import { SignInCreds } from '../../auth/sign-in/sign-in.model'
import { UserClient } from '../../common/clients/user.client'
import { AvatarComponent } from '../../common/components/avatar/avatar.component'
import { ButtonComponent } from '../../common/components/button/button.component'
import { FormFieldErrorComponent } from '../../common/components/form-field-error/form-field-error.component'
import { NotificationService } from '../../common/components/notification/notification.service'
import { PasswordFieldComponent } from '../../common/components/password-field/password-field.component'
import { RoleModalComponent } from '../../common/components/role-modal/role-modal.component'
import { TextInputComponent } from '../../common/components/text-input/text-input.component'
import {
  ERROR_NOTIFICATION_TITLE_KEY,
  SUCCESS_NOTIFICATION_TITLE_KEY,
} from '../../common/constants'
import { getFriendlyRole } from '../../common/functional-libraries/utils.library'
import { UserService } from '../../common/services/user.service'
import { UpdateUser, User, UserRole } from '../../models/user.model'
import { ProfileService } from '../services/profile.service'

@Component({
  selector: 'app-profile-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    AvatarComponent,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
    FormFieldErrorComponent,
    CodeInputModule,
    RouterModule,
    PasswordFieldComponent,
    ImageCropperModule,
    ColorPickerModule,
    ButtonComponent,
    RoleModalComponent,
  ],
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  public user?: User
  public show = false
  public changeEmailVisible = false
  public verificationCodeFieldVisible = false
  public updateAvatarFormVisible = false
  public enteredCode = ''
  public imageChangedEvent?: Event
  public croppedImageUrl?: SafeUrl
  public croppedImage?: File | null
  public colorToggle = false
  public newColor = ''
  public avatarImageChanged = false
  public submitting = false
  public showRoles = false

  public form = this.fb.group({
    id: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    title: [''],
    email: ['', Validators.required],
    role: { value: '', disabled: true },
    avatarColor: [''],
    avatarImageUrl: [''],
    password: [''],
  })

  private formVisibleSubscription: Subscription
  private emailFieldSubscription?: Subscription
  private avatarFileName = 'avatar.png'

  constructor(
    private fb: FormBuilder,
    private userClient: UserClient,
    private authClient: AuthClient,
    private notificationService: NotificationService,
    private translation: TranslocoService,
    private profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {
    this.formVisibleSubscription = profileService
      .getObservable()
      .subscribe((show) => (this.show = show))
  }

  public ngOnInit(): void {
    this.handlePageLoad()
  }

  public ngOnDestroy(): void {
    this.formVisibleSubscription.unsubscribe()
    this.emailFieldSubscription?.unsubscribe()
  }

  public get activateSubmit(): boolean {
    return this.form.valid
  }

  public get avatarUser(): {
    firstName: string
    lastName: string
    avatarColor: string
    avatarImageUrl?: string
  } {
    return {
      firstName: this.user?.firstName ?? '',
      lastName: this.user?.lastName ?? '',
      avatarColor: this.newColor
        ? this.newColor
        : this.user?.avatarColor ?? '#ff3377',
      avatarImageUrl: this.user?.avatarImageUrl,
    }
  }

  public hideVerificationCodeField(): void {
    this.verificationCodeFieldVisible = false
  }

  public hideUpdateAvatarForm(): void {
    this.updateAvatarFormVisible = false
  }

  public showUpdateAvatarForm(): void {
    this.updateAvatarFormVisible = true
  }

  public removePhoto(): void {
    this.croppedImageUrl = undefined
    this.imageChangedEvent = undefined
    this.avatarImageChanged = true
    this.form.get('avatarImageUrl')?.setValue(null)
  }

  public hasError(field: string, error: string): boolean {
    const control = this.form.get(field)
    if (!control || !control.touched) return false

    return control.hasError(error)
  }

  public async onSubmit(): Promise<void> {
    if (this.form.invalid) return

    this.submitting = true

    if (this.changeEmailVisible) {
      this.changeEmail()
      this.submitting = false
      return
    }

    const updateResult = await this.updateUser()

    if (this.avatarImageChanged && !updateResult.err) {
      await this.uploadAvatar()
      this.userService.setCurrentUser(updateResult.safeUnwrap())
    }

    this.resetState()
  }

  public cancel(): void {
    this.profileService.closeProfileForm()
    this.resetState()
  }

  public setCode(code: string): void {
    this.enteredCode = code
  }

  public async changeEmail(): Promise<void> {
    const formValue = this.form.value

    if (!this.user?.email || !formValue.email || !formValue.password) return

    const isVerified = await this.verifyPassword({
      email: this.user.email,
      password: formValue.password,
    })

    if (!isVerified) return

    const args = {
      email: this.user.email,
      password: formValue.password,
      attributes: [
        {
          Name: 'email',
          Value: formValue.email,
        },
      ],
    }

    const result = await this.authClient.getUserAttributeUpdateCode(args)

    if (result.err) {
      this.notificationService.error(
        this.translation.translate(ERROR_NOTIFICATION_TITLE_KEY),
        this.translation.translate(
          'profile-form.notifications.change-email-failed',
        ),
      )
      return
    }

    this.notificationService.success(
      this.translation.translate(SUCCESS_NOTIFICATION_TITLE_KEY),
      this.translation.translate(
        'profile-form.notifications.change-email-success',
        {
          email: formValue.email,
        },
      ),
    )

    this.analyticsService.track({ name: 'email_changed' })

    this.changeEmailVisible = false
    this.verificationCodeFieldVisible = true
  }

  public fileChangeEvent(event: Event): void {
    // eslint-disable-next-line no-console
    console.log('fileChangeEvent ====>>', event)
    this.imageChangedEvent = event
  }

  public imageCropped(event: ImageCroppedEvent): void {
    if (!event.objectUrl || !event.blob) return

    this.croppedImageUrl = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl,
    )

    this.croppedImage = new File([event.blob], this.avatarFileName, {
      type: 'image/png',
    })

    this.form.get('avatarImageUrl')?.setValue(this.avatarFileName)
    this.avatarImageChanged = true
  }

  public loadImageFailed(): void {
    this.notificationService.error(
      this.translation.translate(ERROR_NOTIFICATION_TITLE_KEY),
      this.translation.translate(
        'profile-form.notifications.image-load-failed',
      ),
    )
  }

  public toggleRoles(): void {
    this.showRoles = !this.showRoles
  }

  public toggleChangeEmail(): void {
    const emailField = this.form.get('email')
    const passwordField = this.form.get('password')

    this.changeEmailVisible = !this.changeEmailVisible

    if (this.changeEmailVisible) {
      passwordField?.addValidators(Validators.required)
      this.form.updateValueAndValidity()
      this.changeDetectorRef.detectChanges()
    }

    if (!this.changeEmailVisible) {
      passwordField?.clearValidators()
      passwordField?.setErrors(null)
      this.form.updateValueAndValidity()
      this.changeDetectorRef.detectChanges()
      if (this.user) emailField?.setValue(this.user.email)
    }
  }

  private async updateUser(): Promise<Result<User, Error>> {
    const formValue = this.form.value

    if (
      !formValue.id ||
      !formValue.firstName ||
      !formValue.lastName ||
      !formValue.email ||
      !this.user?.accountId ||
      (formValue.password && !this.enteredCode)
    )
      return Err(new Error('Invalid form data'))

    const user: UpdateUser = {
      id: formValue.id,
      accountId: this.user?.accountId,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      role: formValue.role as UserRole,
      title: formValue.title,
      avatarColor: this.newColor,
      avatarImageUrl: formValue.avatarImageUrl,
    }

    if (
      this.verificationCodeFieldVisible &&
      formValue.password &&
      this.enteredCode
    ) {
      user.authChange = {
        email: formValue.email,
        password: formValue.password,
        verificationCode: this.enteredCode,
        attribute: 'email',
      }
    }

    const saveResult = await this.userClient.update(user)

    if (saveResult.err) {
      this.notificationService.error(
        this.translation.translate(ERROR_NOTIFICATION_TITLE_KEY),
        this.translation.translate('profile-form.notifications.save-failed'),
      )
      return Err(new Error('Error saving user'))
    }

    this.notificationService.success(
      this.translation.translate(SUCCESS_NOTIFICATION_TITLE_KEY),
      this.translation.translate('profile-form.notifications.save-success'),
    )

    this.profileService.closeProfileForm()
    this.handlePageLoad()

    return saveResult
  }

  private async uploadAvatar(): Promise<void> {
    if (!this.croppedImage) return

    const result = await this.userClient.uploadAvatar(this.croppedImage)

    if (result.err) {
      this.notificationService.error(
        this.translation.translate(ERROR_NOTIFICATION_TITLE_KEY),
        this.translation.translate('profile-form.notifications.avatar-failed'),
      )
    }

    this.analyticsService.track({ name: 'avatar_changed' })
  }

  private async handlePageLoad(): Promise<void> {
    await this.getUser()
    this.patchForm()
  }

  private async verifyPassword(creds: SignInCreds): Promise<boolean> {
    if (!this.user?.email) return false

    const loginResult = await this.authClient.signIn(creds)

    if (loginResult.err) {
      this.notificationService.error(
        this.translation.translate(ERROR_NOTIFICATION_TITLE_KEY),
        this.translation.translate(
          'profile-form.notifications.verify-password-failed',
        ),
      )
      return false
    }

    return true
  }

  private async getUser(): Promise<void> {
    const userResult = await this.userClient.getCurrentUser()

    if (userResult.err) {
      this.notificationService.error(
        this.translation.translate('ERROR_NOTIFICATION_TITLE_KEY'),
        this.translation.translate(
          'profile-form.notifications.get-user-failed',
        ),
      )
      return
    }

    this.user = userResult.safeUnwrap()
  }

  private patchForm(): void {
    if (!this.user) return

    this.form.patchValue({
      id: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      title: this.user.title,
      email: this.user.email,
      role: getFriendlyRole(this.user.role),
      avatarColor: this.user.avatarColor,
      avatarImageUrl: this.user.avatarImageUrl,
    })

    this.newColor = this.user.avatarColor
    this.croppedImageUrl = this.user?.avatarImageUrl
  }

  private resetState(): void {
    this.form.get('password')?.setValue(null)
    this.changeEmailVisible = false
    this.verificationCodeFieldVisible = false
    this.updateAvatarFormVisible = false
    this.enteredCode = ''
    this.croppedImageUrl = undefined
    this.imageChangedEvent = undefined
    this.newColor = ''
    this.submitting = false
    this.patchForm()
  }
}
