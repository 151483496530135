import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { authGuard } from './guards/auth.guard'

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./auth/sign-up/sign-up.component').then((x) => x.SignUpComponent),
  },
  {
    path: 'sign-in',
    loadComponent: () =>
      import('./auth/sign-in/sign-in.component').then((x) => x.SignInComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then(
        (x) => x.ForgotPasswordComponent,
      ),
  },
  {
    path: 'enter-verification-code',
    loadComponent: () =>
      import(
        './auth/enter-verification-code/enter-verification-code.component'
      ).then((x) => x.EnterVerificationCodeComponent),
  },
  {
    path: 'create-new-password',
    loadComponent: () =>
      import('./auth/create-new-password/create-new-password.component').then(
        (x) => x.CreateNewPasswordComponent,
      ),
  },
  {
    path: 'docs',
    canActivateChild: [authGuard],
    children: [
      {
        path: 'external',
        loadComponent: () =>
          import('./docs/external/external-redirect.component').then(
            (x) => x.ExternalRedirectComponent,
          ),
      },
    ],
  },
  {
    path: 'schools',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./school/school-list/school-list.component').then(
            (x) => x.SchoolListComponent,
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./school/school-details/school-details.component').then(
            (x) => x.SchoolDetailsComponent,
          ),
      },
    ],
  },
  {
    path: 'team-members',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './team-member/team-member-list/team-member-list.component'
          ).then((x) => x.TeamMemberListComponent),
      },
      {
        path: ':id',
        loadComponent: () =>
          import(
            './team-member/team-member-details/team-member-details.component'
          ).then((x) => x.TeamMemberDetailsComponent),
      },
    ],
  },
  {
    path: 'students',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./student/student-list/student-list.component').then(
            (x) => x.StudentListComponent,
          ),
      },
      {
        path: 'lessons/:id',
        loadComponent: () =>
          import('./lesson/lesson-manager/lesson-manager.component').then(
            (x) => x.LessonManagerComponent,
          ),
      },
      {
        path: ':studentId/lesson/:lessonId',
        loadComponent: () =>
          import('./lesson/lesson-preview/lesson-preview.component').then(
            (x) => x.LessonPreviewComponent,
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./student/student-details/student-details.component').then(
            (x) => x.StudentDetailsComponent,
          ),
      },
    ],
  },
  {
    path: 'lessons',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./lesson/lesson-list/lesson-list.component').then(
            (x) => x.LessonListComponent,
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./lesson/lesson-details/lesson-details.component').then(
            (x) => x.LessonDetailsComponent,
          ),
      },
    ],
  },
  {
    path: 'reports',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./report/reports/reports.component').then(
            (x) => x.ReportsComponent,
          ),
      },
      {
        path: 'progress',
        loadComponent: () =>
          import(
            './report/reports/teacher-progress-report/teacher-progress-report.component'
          ).then((x) => x.TeacherProgressReportComponent),
      },
      {
        path: 'account-active-vs-completed',
        loadComponent: () =>
          import(
            './report/reports/account-active-vs-complete/account-active-vs-complete.component'
          ).then((x) => x.AccountActiveVsCompleteComponent),
      },
      {
        path: 'school-active-vs-completed',
        loadComponent: () =>
          import(
            './report/reports/school-active-vs-complete/school-active-vs-complete.component'
          ).then((x) => x.SchoolActiveVsCompleteComponent),
      },
      {
        path: 'team-member-active-vs-completed',
        loadComponent: () =>
          import(
            './report/reports/team-member-active-vs-complete/team-member-active-vs-complete.component'
          ).then((x) => x.TeamMemberActiveVsCompleteComponent),
      },
      {
        path: 'teacher-active-vs-completed',
        loadComponent: () =>
          import(
            './report/reports/teacher-active-vs-complete/teacher-active-vs-complete.component'
          ).then((x) => x.TeacherActiveVsCompleteComponent),
      },
      {
        path: 'print',
        children: [
          {
            path: 'teacher-active-vs-complete',
            loadComponent: () =>
              import(
                './report/reports/teacher-active-vs-complete/teacher-active-vs-complete.component'
              ).then((x) => x.TeacherActiveVsCompleteComponent),
          },
          {
            path: 'progress',
            loadComponent: () =>
              import(
                './report/reports/teacher-progress-report/teacher-progress-report-print-view/teacher-progress-report-print-view.component'
              ).then((x) => x.TeacherProgressReportPrintViewComponent),
          },
          {
            path: 'account-active-vs-complete',
            loadComponent: () =>
              import(
                './report/reports/account-active-vs-complete/account-active-vs-complete.component'
              ).then((x) => x.AccountActiveVsCompleteComponent),
          },
          {
            path: 'school-active-vs-complete',
            loadComponent: () =>
              import(
                './report/reports/school-active-vs-complete/school-active-vs-complete.component'
              ).then((x) => x.SchoolActiveVsCompleteComponent),
          },
          {
            path: 'team-member-active-vs-complete',
            loadComponent: () =>
              import(
                './report/reports/team-member-active-vs-complete/team-member-active-vs-complete.component'
              ).then((x) => x.TeamMemberActiveVsCompleteComponent),
          },
        ],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
