import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { ConfirmationDialog } from './confirmation-dialog.model'

@Injectable()
export class ConfirmationDialogService {
  private subject = new Subject<ConfirmationDialog>()

  public getObservable(): Observable<ConfirmationDialog> {
    return this.subject.asObservable()
  }

  public open(confirmationDialog: ConfirmationDialog): void {
    const {
      title,
      message,
      icon,
      iconClass,
      confirmButtonText,
      cancelButtonText,
      timeRemaining,
      canCloseSelf,
      callback,
    } = confirmationDialog

    this.subject.next({
      show: true,
      title,
      message,
      icon,
      iconClass,
      confirmButtonText,
      cancelButtonText,
      timeRemaining,
      canCloseSelf,
      callback,
    })
  }

  public close(): void {
    this.subject.next({
      show: false,
      title: '',
      message: '',
      icon: '',
      iconClass: '',
      confirmButtonText: '',
      cancelButtonText: '',
      timeRemaining: undefined,
      canCloseSelf: undefined,
      callback: undefined,
    })
  }
}
