import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular'
import {
  AmplitudeProvider,
  AnalyticsProviderType,
  AnalyticsServiceConfiguration,
  AnalyticsServiceModule,
} from '@uptechworks/analytics-service-angular'
import { CodeInputModule } from 'angular-code-input'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { provideUserIdleConfig } from 'angular-user-idle'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthService } from './auth/services/auth.service'
import { ConfirmationDialogComponent } from './common/components/confirmation-dialog/confirmation-dialog.component'
import { ConfirmationDialogService } from './common/components/confirmation-dialog/confirmation-dialog.service'
import { NavbarMainComponent } from './common/components/navbar-main/navbar-main.component'
import { NotificationComponent } from './common/components/notification/notification.component'
import { NotificationService } from './common/components/notification/notification.service'
import { UserSessionService } from './common/services/user-session.service'
import { httpInterceptorProviders } from './interceptors/interceptor.provider'
import { LessonPreviewService } from './lesson/lesson-preview/lesson-preview.service'
import { ProfileFormComponent } from './profile/profile-form/profile-form.component'
import { ProfileService } from './profile/services/profile.service'
import { TranslocoRootModule } from './transloco-root.module'

const AnalyticsConfig: AnalyticsServiceConfiguration = {
  analyticsProviderConfig: [
    {
      providerType: AnalyticsProviderType.amplitude,
      provider: new AmplitudeProvider({
        apiKey: environment.amplitudeApiKey,
        config: {
          blockedUserAgentStrings: ['NewRelic'],
          defaultTracking: false,
        },
      }),
    },
  ],
  logLevel: 'DEBUG',
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    AngularSvgIconModule.forRoot(),
    NavbarMainComponent,
    NotificationComponent,
    ConfirmationDialogComponent,
    CodeInputModule.forRoot({ codeLength: 6 }),
    ProfileFormComponent,
    AnalyticsServiceModule.forRoot(AnalyticsConfig),
  ],
  providers: [
    httpInterceptorProviders,
    AuthService,
    NotificationService,
    ConfirmationDialogService,
    provideUserIdleConfig({ idle: 900, timeout: 60 }),
    UserSessionService,
    ProfileService,
    LessonPreviewService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
