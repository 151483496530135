import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class LessonPreviewService {
  private subject = new Subject<void>()

  public getObservable(): Observable<void> {
    return this.subject.asObservable()
  }

  public reload(): void {
    this.subject.next()
  }
}
