import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { AnalyticsService } from '@uptechworks/analytics-service-angular'
import { UserIdleService } from 'angular-user-idle'
import { Subscription } from 'rxjs'
import { AuthService } from '../../auth/services/auth.service'
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service'

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private timerSubscription?: Subscription
  private timeoutSubscription?: Subscription
  private lastActivePage: string | null = null

  constructor(
    private userIdle: UserIdleService,
    private authService: AuthService,
    private confirmation: ConfirmationDialogService,
    private router: Router,
    private translation: TranslocoService,
    private analyticsService: AnalyticsService,
  ) {}

  public setLastActivePage(url: string): void {
    localStorage.setItem('lastActivePage', url)
  }

  public getLastActivePage(): string | null {
    return localStorage.getItem('lastActivePage')
  }

  public clearLastActivePage(): void {
    localStorage.removeItem('lastActivePage')
  }

  public start(): void {
    this.userIdle.startWatching()
    this.analyticsService.track({ name: 'user_session_started' })
    this.timerSubscription = this.userIdle.onTimerStart().subscribe((count) => {
      if (count === 1) {
        this.analyticsService.track({ name: 'user_session_timeout_warning' })

        this.confirmation.open({
          show: true,
          icon: 'warning',
          timeRemaining: 60,
          title: this.translation.translate(
            'user-session-service.time-out-warning.title',
          ),
          message: this.translation.translate(
            'user-session-service.time-out-warning.message',
          ),
          confirmButtonText: this.translation.translate(
            'user-session-service.time-out-warning.confirm',
          ),
          cancelButtonText: this.translation.translate(
            'user-session-service.time-out-warning.cancel',
          ),
          callback: (response: boolean) => this.handleResponse(response),
        })
      }
    })

    this.timeoutSubscription = this.userIdle
      .onTimeout()
      .subscribe(() => this.handleSignOut())
  }

  public stop(): void {
    this.userIdle.stopTimer()
    this.userIdle.stopWatching()
    this.timerSubscription?.unsubscribe()
    this.timeoutSubscription?.unsubscribe()
    this.analyticsService.track({ name: 'user_session_timed_out' })
  }

  private handleResponse(response: boolean): void {
    if (!response) {
      this.handleSignOut()
      return
    }

    this.userIdle.resetTimer()
    this.confirmation.close()
  }

  private handleSignOut(): void {
    this.userIdle.resetTimer()
    this.confirmation.close()
    this.authService.signOut()
    this.stop()
    this.setLastActivePage(this.router.url)
    this.router.navigate(['/sign-in'])
    this.confirmation.open({
      show: true,
      icon: 'info',
      title: this.translation.translate(
        'user-session-service.timed-out-message.title',
      ),
      message: this.translation.translate(
        'user-session-service.timed-out-message.message',
      ),
      confirmButtonText: this.translation.translate(
        'user-session-service.timed-out-message.confirm',
      ),
      callback: () => this.confirmation.close(),
    })
  }
}
