import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ShimmerLoaderService {
  private apiCount = 0
  private isLoadingSubject = new BehaviorSubject<boolean>(false)
  public isLoading$ = this.isLoadingSubject.asObservable()

  public showLoader(): void {
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true)
    }
    this.apiCount++
  }

  public hideLoader(): void {
    this.apiCount--
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false)
    }
  }
}
