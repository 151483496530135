import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'
import { Notification, NotificationType } from './notification.model'

@Injectable()
export class NotificationService {
  private subject = new Subject<Notification>()
  private idx = 0

  public getObservable(): Observable<Notification> {
    return this.subject.asObservable()
  }

  public info(title: string, message: string, timeout = 5000): void {
    this.subject.next(
      new Notification(
        this.idx++,
        NotificationType.INFO,
        title,
        message,
        timeout,
      ),
    )
  }

  public success(title: string, message: string, timeout = 5000): void {
    this.subject.next(
      new Notification(
        this.idx++,
        NotificationType.SUCCESS,
        title,
        message,
        timeout,
      ),
    )
  }

  public warning(title: string, message: string, timeout = 5000): void {
    this.subject.next(
      new Notification(
        this.idx++,
        NotificationType.WARNING,
        title,
        message,
        timeout,
      ),
    )
  }

  public error(title: string, message: string, timeout = 10000): void {
    this.subject.next(
      new Notification(
        this.idx++,
        NotificationType.ERROR,
        title,
        message,
        timeout,
      ),
    )
  }
}
