import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { getUserInitials } from '../../functional-libraries/utils.library'

export type UserName = {
  firstName: string
  lastName: string
}

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input()
  public user?: {
    firstName: string
    lastName: string
    avatarColor: string
    avatarImageUrl?: string
  }

  @Input()
  public school?: {
    name: string
    avatarColor: string
  }

  @Input()
  public displayText?: string

  @Input()
  public color?: string

  @Input()
  public showTooltip = true

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && changes['user']['currentValue'])
      this.user = changes['user']['currentValue']

    if (changes['school'] && changes['school']['currentValue'])
      this.school = changes['school']['currentValue']
  }

  public get initialsToDisplay(): string {
    if (this.school) return this.school.name.slice(0, 1).toUpperCase()

    if (this.user) return getUserInitials(this.user)

    if (this.displayText) return this.displayText

    return ''
  }

  public getTooltipName(): string {
    if (this.school) return this.school.name

    if (this.user) return `${this.user.firstName} ${this.user.lastName}`

    return ''
  }

  public get avatarColor(): string {
    if (this.school) return this.school.avatarColor

    if (this.user) return this.user.avatarColor

    if (this.color) return this.color

    return ''
  }

  public get imageUrl(): string {
    if (this.user && this.user.avatarImageUrl) return this.user.avatarImageUrl

    return ''
  }
}
