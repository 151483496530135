<div *transloco="let t">
  <label
    [for]="formControlName"
    class="block text-sm font-headline font-medium leading-6"
    [ngClass]="isInvalid ? 'text-red-500' : 'text-gray-label'"
  >
    {{ t(label ?? '') }}
  </label>

  <div class="relative mt-2 rounded-md shadow-sm">
    <input
      [type]="showPassword ? 'password' : 'text'"
      [formControl]="control"
      [name]="formControlName"
      [id]="formControlName"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:cursor-not-allowed disabled:bg-gray-disabled disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
      [ngClass]="{
        'ring-gray-400 focus:ring-gray-500': !isInvalid,
        'ring-red-500 focus:ring-red-500': isInvalid
      }"
      tabindex="1"
      [attr.aria-invalid]="isInvalid"
      [attr.aria-describedby]="feedbackLabel"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [disabled]="disabled"
      [autofocus]="focus"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div class="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
      <span *ngIf="isInvalid" class="material-icons text-red-500"> error </span>

      <span
        class="material-icons hover:cursor-pointer"
        (click)="toggleVisibility()"
      >
        {{ showPassword ? 'visibility_off' : 'visibility' }}
      </span>
    </div>

    <span *ngIf="disabled" class="material-icons text-gray-900"> lock </span>
  </div>

  <div class="h-6 pt-1">
    <ng-content></ng-content>
  </div>

  <p
    *ngIf="hint && !isInvalid"
    class="mt-2 text-xs font-normal text-gray-500"
    id="password-description"
  >
    {{ t(hint) }}
  </p>

  <div class="text-xs text-gray-500 font-semibold py-3" *ngIf="showHelper">
    <div class="pb-3">{{ t('password-field.helper-text') }}</div>

    <div class="grid grid-cols-2 gap-3">
      <app-password-checklist-item
        requirement="min-length"
        [value]="value"
      ></app-password-checklist-item>

      <app-password-checklist-item
        requirement="uppercase"
        [value]="value"
      ></app-password-checklist-item>

      <app-password-checklist-item
        requirement="lowercase"
        [value]="value"
      ></app-password-checklist-item>

      <app-password-checklist-item
        requirement="number"
        [value]="value"
      ></app-password-checklist-item>

      <app-password-checklist-item
        requirement="special-character"
        [value]="value"
      ></app-password-checklist-item>
    </div>
  </div>
</div>
