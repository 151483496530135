<div
  class="h-full w-full rounded-full text-white group flex relative items-center justify-center tracking-tighter"
  [style.background]="avatarColor"
>
  <ng-container *ngIf="!imageUrl">
    {{ initialsToDisplay }}
  </ng-container>

  <img
    *ngIf="imageUrl"
    class="inline-block h-full w-full rounded-full"
    [src]="imageUrl"
  />

  <span
    *ngIf="showTooltip"
    class="overflow-visible group-hover:block hidden border border-1 border-gray-400 bg-gray-100 p-2 text-sm text-gray-900 rounded-md absolute left-1/2 top-5 -translate-x-1/2 m-4 mx-auto z-10"
  >
    <span
      class="absolute -top-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 border-t border-l border-gray-400 bg-gray-100"
    ></span>
    {{ getTooltipName() }}
  </span>
  <ng-content></ng-content>
</div>
