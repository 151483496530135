<button
  *ngIf="!isLoading"
  [type]="buttonType"
  [class]="'rounded-md font-medium ' + addClass"
  [ngClass]="{
    'bg-red-primary text-white hover:bg-red-500': buttonStyle === 'primary',
    'bg-gray-white text-gray-900 ring-1 ring-inset ring-red-300 hover:bg-gray-50':
      buttonStyle === 'secondary',
    'bg-red-100 text-red-700 hover:bg-red-50': buttonStyle === 'tertiary',
    'text-red-primary hover:text-red-500': buttonStyle === 'text',
    'opacity-20': isDisabled,
    xxs: buttonSize === '2xs',
    xs: buttonSize === 'xs',
    sm: buttonSize === 'sm',
    md: buttonSize === 'md',
    lg: buttonSize === 'lg',
    xl: buttonSize === 'xl',
    'inline-flex items-center justify-center': icon || isBusy,
    'bg-red-50': active && buttonStyle === 'text',
    'bg-white': !active && buttonStyle === 'text'
  }"
  (click)="onClick($event)"
>
  <app-spinner
    *ngIf="isBusy"
    [isBusy]="isBusy"
    addClass="h-5 w-5"
    [ngClass]="{ '-ml-1 mr-3': hasContent || buttonText }"
  ></app-spinner>
  <span
    *ngIf="icon && !isBusy && icon !== 'fa-airplane'"
    [ngClass]="getIconClass()"
  >
    {{ icon }}
  </span>
  <span *ngIf="icon && !isBusy && icon === 'fa-airplane'">
    <svg-icon
      src="../../assets/images/airplane-icon.svg"
      [svgStyle]="{ 'width.px': 20 }"
    ></svg-icon>
  </span>
  <span #buttonContent>
    <ng-content></ng-content>
  </span>
</button>

<div
  *ngIf="isLoading"
  class="h-full w-32 flex items-center justify-center shimmer-animate"
>
  &nbsp;
</div>
