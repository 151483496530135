/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, inject, Injector, OnDestroy, OnInit } from '@angular/core'
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms'
import { Subscription } from 'rxjs'

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HostControlDirective,
    },
  ],
})
export class HostControlDirective
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public control!: FormControl

  private injector = inject(Injector)
  private subscription?: Subscription

  public onChange: (value: any) => void = () => {}

  public ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, {
      self: true,
      optional: true,
    })

    if (ngControl instanceof NgModel) {
      this.control = ngControl.control
      this.subscription = ngControl.control.valueChanges.subscribe((value) => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value)
        }
      })
    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control
    } else if (ngControl instanceof FormControlName && ngControl.name) {
      const container = this.injector.get(ControlContainer).control as FormGroup
      this.control = container.controls[ngControl.name] as FormControl
    } else {
      this.control = new FormControl()
    }
  }

  public writeValue(): void {}
  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn
  }
  public registerOnTouched(): void {}

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }
}
