import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { User, UserRole } from '../../models/user.model'
import { AnalyticsService } from '@uptechworks/analytics-service-angular'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class UserService {
  public currentUser$ = new BehaviorSubject<User | undefined>(undefined)

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
  ) {}

  public setCurrentUser(user: User): void {
    this.analyticsService.setCurrentUser({
      userId: user.id,
      properties: {
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
        account: user.accountName,
      },
    })

    this.currentUser$.next(user)
  }

  public get currentUser(): User | undefined {
    return this.currentUser$.value
  }

  public hasRole(roles: UserRole[]): boolean {
    if (!this.currentUser) return false
    return roles.includes(this.currentUser?.role)
  }

  public getHomeRoute(): string {
    if (!this.currentUser) return '/'

    if (
      [UserRole.TEACHER, UserRole.PARAEDUCATOR].includes(this.currentUser.role)
    ) {
      return '/students'
    }

    return '/schools'
  }

  public goToHomeRoute(): void {
    this.router.navigate([this.getHomeRoute()])
  }
}
