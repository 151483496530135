export enum CsvUploadIssueCode {
  INVALID_TYPE = 'invalid_type',
  INVALID_LITERAL = 'invalid_literal',
  CUSTOM = 'custom',
  INVALID_UNION = 'invalid_union',
  INVALID_UNION_DISCRIMINATOR = 'invalid_union_discriminator',
  INVALID_ENUM_VALUE = 'invalid_enum_value',
  UNRECOGNIZED_KEYS = 'unrecognized_keys',
  INVALID_ARGUMENTS = 'invalid_arguments',
  INVALID_RETURN_TYPE = 'invalid_return_type',
  INVALID_DATE = 'invalid_date',
  INVALID_STRING = 'invalid_string',
  TOO_SMALL = 'too_small',
  TOO_BIG = 'too_big',
  INVALID_INTERSECTION_TYPES = 'invalid_intersection_types',
  NOT_MULTIPLE_OF = 'not_multiple_of',
  NOT_FINITE = 'not_finite',
  WRONG_TEMPLATE = 'wrong_template',
  DATABASE_ERROR = 'database_error',
  DUPLICATE = 'duplicate',
  MULTIPLE_FILES = 'multiple_files',
  INVALID_FILE_EXTENSION = 'invalid_file_extension',
  STUDENT_COUNT_EXCEEDED = 'student_count_exceeded',
  INVALID_USER_ROLE = 'invalid_user_role',
  SCHOOL_NOT_FOUND = 'school_not_found',
}

export type CsvUploadError = {
  line?: number
  field?: string
  code: CsvUploadIssueCode
  message: string
}
