/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  inject,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core'
import {
  ControlContainer,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'
import { TranslocoModule } from '@ngneat/transloco'
import { AutofocusDirective } from '../../directives/auto-focus.directive'
import { ValueAccessorDirective } from '../../directives/value-accessor.directive'
import { PasswordChecklistItemComponent } from '../password-checklist-item/password-checklist-item.component'

@Component({
  selector: 'app-password-field',
  standalone: true,
  templateUrl: 'password-field.component.html',
  styleUrls: ['password-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    PasswordChecklistItemComponent,
    AutofocusDirective,
  ],
  hostDirectives: [ValueAccessorDirective],
})
export class PasswordFieldComponent {
  public value: any
  public disabled = false
  public showPassword = true
  public hasError = false
  public isFocused = false

  @ViewChild('inputField') public inputField!: ElementRef

  @Input() public formControlName!: string

  @Input() public label?: string

  @Input()
  public placeholder = ''

  @Input()
  public hint = ''

  @Input()
  public showHelper = false

  @Input()
  public mask = ''

  @Input()
  public matchFormControlName?: string

  @Input()
  public focus = false

  @Input()
  public updateOnKeyup = false

  @Input()
  public updateOnKeydown = false

  public valueAccessor = inject(ValueAccessorDirective<string>)

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
    this.valueAccessor.value.subscribe((v) => (this.value = v))
    this.valueAccessor.disabled.subscribe((v) => (this.disabled = v))
  }

  public get control(): FormControl {
    return this.valueAccessor.control
  }

  public toggleVisibility(): void {
    this.showPassword = !this.showPassword
  }

  public get isInvalid(): boolean {
    return (
      (!this.isFocused &&
        this.control?.invalid &&
        (this.control?.dirty || this.control?.touched)) ??
      false
    )
  }

  public get feedbackLabel(): string {
    return this.formControlName + '-feedback-label'
  }

  public onFocus(): void {
    this.isFocused = true
  }

  public onBlur(): void {
    this.isFocused = false
  }
}
