/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common'
import { Component, Input, inject } from '@angular/core'
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'
import { AutofocusDirective } from '../../directives/auto-focus.directive'
import { HostControlDirective } from '../../directives/host-control-directive'
import { ShimmerFormFieldComponent } from '../shimmer/shimmer-form-field/shimmer-form-field.component'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutofocusDirective,
    ShimmerFormFieldComponent,
  ],
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  hostDirectives: [HostControlDirective],
})
export class TextInputComponent {
  public hcd = inject(HostControlDirective)

  private onChange: ((value: string) => void) | undefined

  @Input() public name!: string

  @Input() public label?: string

  @Input() public inputType:
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'url' = 'text'

  @Input()
  public placeholder = ''

  @Input()
  public hint = ''

  @Input()
  public mask = ''

  @Input()
  public leadingIcon?: string

  @Input()
  public focus = false

  @Input()
  public maxLength?: number

  @Input()
  public isLoading = false

  @Input()
  public trim = true

  public get isInvalid(): boolean {
    return (this.control?.invalid && this.control?.touched) ?? false
  }

  public get feedbackLabel(): string {
    return this.name + '-feedback-label'
  }

  public get control(): AbstractControl {
    return this.hcd.control
  }

  public get isDisabled(): boolean {
    return this.control.disabled
  }

  public onBlur(event: FocusEvent): void {
    const target = event.target as HTMLTextAreaElement
    if (this.trim) target.value = target.value.trim()
    this.hcd.onChange(target.value)
  }
}
