<div *ngFor="let notification of notifications" class="mb-2">
  <ng-container
    *ngTemplateOutlet="notificationTpl; context: { notification: notification }"
  ></ng-container>
</div>

<ng-template #notificationTpl let-notification="notification">
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-14 flex items-center justify-center px-4 py-6 sm:items-start sm:p-6 z-50"
    role="alert"
  >
    <div
      class="flex w-full max-w-lg flex-col items-center space-y-4 sm:items-end"
    >
      <div
        class="pointer-events-auto w-full overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5"
        [ngClass]="{
          'bg-emerald-50': notification.type === NotificationType.SUCCESS,
          'bg-red-50': notification.type === NotificationType.ERROR,
          'bg-yellow-50': notification.type === NotificationType.WARNING
        }"
      >
        <div class="p-4 justify-center">
          <div class="flex items-center">
            <div
              [ngSwitch]="notification.type"
              class="flex-shrink-0 flex items-center"
            >
              <span
                *ngSwitchCase="NotificationType.SUCCESS"
                class="material-icons text-green-400"
              >
                check_circle
              </span>

              <span
                *ngSwitchCase="NotificationType.ERROR"
                class="material-icons text-red-400"
              >
                error
              </span>

              <span
                *ngSwitchCase="NotificationType.WARNING"
                class="material-icons text-yellow-400"
              >
                warning
              </span>
            </div>

            <div class="ml-3 w-0 flex-1 pt-0.5 inline-flex items-center">
              <p
                class="text-sm font-medium mr-3"
                [ngClass]="{
                  'text-green-800':
                    notification.type === NotificationType.SUCCESS,
                  'text-red-800': notification.type === NotificationType.ERROR,
                  'text-yellow-800':
                    notification.type === NotificationType.WARNING
                }"
              >
                {{ notification.title }}
              </p>

              <p
                class="text-sm"
                [ngClass]="{
                  'text-green-700':
                    notification.type === NotificationType.SUCCESS,
                  'text-red-700': notification.type === NotificationType.ERROR,
                  'text-yellow-700':
                    notification.type === NotificationType.WARNING
                }"
              >
                {{ notification.message }}
              </p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                type="button"
                class="inline-flex rounded-md focus:outline-none"
                [ngClass]="{
                  'text-green-400 hover:text-green-500':
                    notification.type === NotificationType.SUCCESS,
                  'text-red-400 hover:text-red-500':
                    notification.type === NotificationType.ERROR,
                  'text-yellow-400 hover:text-yellow-500':
                    notification.type === NotificationType.WARNING
                }"
                (click)="close(notification)"
              >
                <span class="sr-only">Close</span>
                <span class="material-icons">close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
