import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, firstValueFrom, map, of } from 'rxjs'
import { Err, Ok, Result } from 'ts-results'
import { environment } from '../../../environments/environment'
import { handleBasicHttpError } from '../../common/functional-libraries/utils.library'
import { AuthSession } from '../models/auth-session.model'
import { ConfirmPasswordResetInput } from '../models/confirm-password-reset.model'
import { ForgotPasswordInput } from '../models/forgot-password-input.model'
import { RefreshTokenCreds } from '../models/refresh-token-creds.model'
import { UpdateUserAttributesInput } from '../models/update-user-attributes.model'
import { SignInCreds } from '../sign-in/sign-in.model'
import { SignUpCreds } from '../sign-up/sign-up.model'

@Injectable({
  providedIn: 'root',
})
export class AuthClient {
  constructor(private http: HttpClient) {}

  public signUp(creds: SignUpCreds): Promise<Result<string, Error>> {
    const result = this.http
      .post(`${environment.apiUrl}/auth/accounts`, creds, {
        responseType: 'text',
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<string>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public signIn(creds: SignInCreds): Promise<Result<AuthSession, Error>> {
    const result = this.http
      .post<AuthSession>(`${environment.apiUrl}/auth/sessions`, creds)
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) => {
          if (error.error && error.error.message === 'ACCOUNT_NOT_ACTIVE')
            return of(Err(error.error))

          return handleBasicHttpError<AuthSession>(error)
        }),
      )

    return firstValueFrom(result)
  }

  public refreshToken(
    creds: RefreshTokenCreds,
  ): Promise<Result<AuthSession, Error>> {
    const { email, refreshToken } = creds
    const result = this.http
      .put<AuthSession>(`${environment.apiUrl}/auth/sessions`, {
        email,
        refreshToken,
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<AuthSession>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public forgotPassword(
    creds: ForgotPasswordInput,
  ): Promise<Result<boolean, Error>> {
    const { email } = creds
    const result = this.http
      .post<boolean>(`${environment.apiUrl}/auth/forgot-password`, { email })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<boolean>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public confirmPasswordReset(
    creds: ConfirmPasswordResetInput,
  ): Promise<Result<string, Error>> {
    const result = this.http
      .post(`${environment.apiUrl}/auth/confirm-password-reset`, creds, {
        responseType: 'text',
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<string>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public getUserAttributeUpdateCode(
    input: UpdateUserAttributesInput,
  ): Promise<Result<string, Error>> {
    const result = this.http
      .post(`${environment.apiUrl}/auth/update-user-attributes`, input, {
        responseType: 'text',
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<string>(error),
        ),
      )

    return firstValueFrom(result)
  }
}
