import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout'
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { distinctUntilChanged } from 'rxjs'

@Component({
  selector: 'app-role-modal',
  standalone: true,
  imports: [CommonModule, LayoutModule],
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss'],
})
export class RoleModalComponent implements OnInit {
  @Output()
  public buttonClicked = new EventEmitter<undefined>()
  public imagePath = ''

  public readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Small, Breakpoints.XLarge])
    .pipe(distinctUntilChanged())

  constructor(private breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    this.breakpoint$.subscribe(() => this.breakpointChanged())
  }

  public Breakpoints = Breakpoints

  public handleButtonClick(): void {
    this.buttonClicked.emit()
  }

  private breakpointChanged(): void {
    if (
      this.breakpointObserver.isMatched(Breakpoints.Large) ||
      this.breakpointObserver.isMatched(Breakpoints.XLarge)
    ) {
      this.imagePath =
        '../../../../assets/images/permissions-table-large-screen.png'
    } else {
      this.imagePath =
        '../../../../assets/images/permissions-table-small-screen.png'
    }
  }
}
