import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SvgIconComponent } from 'angular-svg-icon'

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input()
  public isBusy?: boolean

  @Input()
  public addClass?: string
}
