// unfortunately we can't get the regex from our user pool

// https://stackoverflow.com/a/58767981/2789806
export const PASSWORD_REGEX_NO_LEADING_SPACES = /(?!\s+)/
export const PASSWORD_REGEX_NO_TRAILING_SPACES = /(?!.*\s+$)/
export const PASSWORD_REGEX_LOWERCASE = /(?=.*[a-z])/
export const PASSWORD_REGEX_UPPERCASE = /(?=.*[A-Z])/
export const PASSWORD_REGEX_NUMBER = /(?=.*[0-9])/
export const PASSWORD_REGEX_SPECIAL =
  /(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S/
export const PASSWORD_REGEX = new RegExp(
  '^' +
    PASSWORD_REGEX_NO_LEADING_SPACES.source +
    PASSWORD_REGEX_NO_TRAILING_SPACES.source +
    PASSWORD_REGEX_LOWERCASE.source +
    PASSWORD_REGEX_UPPERCASE.source +
    PASSWORD_REGEX_NUMBER.source +
    PASSWORD_REGEX_SPECIAL.source +
    '{8,256}$',
)
export const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/
export const ERROR_NOTIFICATION_TITLE_KEY = 'common.errors.notification-title'
export const SUCCESS_NOTIFICATION_TITLE_KEY = 'common.success'
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
