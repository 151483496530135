import { VerifyUpdateUserAttributesInput } from '../auth/models/verify-update-user-attributes.model'

export enum UserRole {
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  TEACHER = 'TEACHER',
  PARAEDUCATOR = 'PARAEDUCATOR',
}

export type User = {
  id: string
  accountId: string
  accountName: string
  crmId?: string
  authId?: string
  firstName: string
  lastName: string
  email: string
  role: UserRole
  avatarColor: string
  avatarImageUrl?: string
  notes?: string
  title?: string
}

export type UpdateUser = {
  id: string
  accountId: string
  crmId?: string
  authId?: string
  firstName?: string
  lastName?: string
  email?: string
  role?: UserRole
  avatarColor?: string
  avatarImageUrl?: string | null
  notes?: string
  title?: string | null
  authChange?: VerifyUpdateUserAttributesInput
}
