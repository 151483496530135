/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { ShimmerLoaderService } from '../common/components/shimmer/full-screen-loader.service'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loader: ShimmerLoaderService) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isApiRequest(req.url) || req.method !== 'GET')
      return next.handle(req)

    this.loader.showLoader()
    return next.handle(req).pipe(finalize(() => this.loader.hideLoader()))
  }

  private isApiRequest(url: string): boolean {
    return !url.includes('assets')
  }
}
