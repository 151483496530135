import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { SpinnerComponent } from '../spinner/spinner.component'

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, SpinnerComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements AfterViewInit {
  @ViewChildren('buttonContent')
  public buttonContent?: QueryList<ElementRef>

  @Input()
  public buttonType: 'button' | 'submit' = 'button'

  @Input()
  public buttonStyle: 'primary' | 'secondary' | 'tertiary' | 'text' = 'primary'

  @Input()
  public buttonSize: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md'

  @Input()
  public buttonText?: string

  @Input()
  public isDisabled = false

  @Input()
  public icon?: string

  @Input()
  public iconOutline = false

  @Input()
  public iconSize:
    | '2xs'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | 'inherit' = 'md'

  @Input()
  public isBusy?: boolean

  @Input()
  public isLoading = false

  @Input()
  public active = false

  @Input()
  public addClass?: string

  @Output()
  public clicked = new EventEmitter<MouseEvent>()

  public hasContent = false

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.hasContent = !!this.buttonContent?.first.nativeElement.innerHTML
    this.changeDetectorRef.detectChanges()
  }

  public onClick(event: MouseEvent): void {
    this.clicked.emit(event)
  }

  public getIconClass(): string {
    let iconClass = ''

    if (this.iconSize === 'inherit') {
      iconClass = 'icon-inherit'
    } else {
      iconClass = `text-${this.iconSize}`
    }

    if (this.hasContent || this.buttonText) iconClass += ' -ml-0.5 mr-1.5'

    if (this.iconOutline) {
      iconClass += ' material-icons-outlined'
    } else {
      iconClass += ' material-icons'
    }

    return iconClass
  }
}
