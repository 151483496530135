import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  createUrlTreeFromSnapshot,
} from '@angular/router'
import { Observable, map } from 'rxjs'
import { AuthService } from '../auth/services/auth.service'

export const authGuard = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<true | UrlTree> => {
  return inject(AuthService).isLoggedIn$.pipe(
    map((isLoggedIn) =>
      isLoggedIn
        ? true
        : createUrlTreeFromSnapshot(next, ['/', 'sign-in'], {
            returnUrl: state.url,
          }),
    ),
  )
}
