<div
  class="relative z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  *ngIf="show"
>
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-auto"
  ></div>

  <div class="fixed inset-0 z-10 overflow-y-auto" (click)="closeSelf()">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
      >
        <div
          *ngIf="canCloseSelf"
          class="absolute right-0 top-0 hidden px-4 pt-4 sm:block"
        >
          <button
            type="button"
            class="z-10 bg-white text-gray-400 hover:text-gray-500"
            (click)="closeSelf()"
          >
            <span class="sr-only">Close</span>
            <span class="material-icons">close</span>
          </button>
        </div>
        <div>
          <span
            class="material-icons text-6xl mx-auto flex items-center justify-center"
            [ngClass]="[iconClass ? iconClass : 'text-red-700']"
          >
            {{ icon }}
          </span>

          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-xl font-headline font-medium leading-6 text-gray-900"
              id="modal-title"
            >
              {{ title }}
            </h3>
            <div class="mt-2">
              <p class="text-sm font-body font-normal text-gray-600">
                {{ message }}

                <ng-container *ngIf="showCountDown">
                  ({{ timeRemaining$ | async | date: 's' }} seconds)
                </ng-container>
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md bg-red-primary px-3 py-2 text-sm font-medium font-headline text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4"
            (click)="respond(true)"
          >
            {{ confirmButtonText }}
          </button>

          <button
            *ngIf="cancelButtonText"
            type="button"
            class="inline-flex w-full justify-center rounded-md text-red-primary px-3 py-2 text-sm font-medium font-headline hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            (click)="respond(false)"
          >
            {{ cancelButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
