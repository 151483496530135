<main>
  <div class="relative w-full">
    <app-navbar-main *ngIf="showNavbar"></app-navbar-main>

    <div [ngClass]="{ 'mt-14': !isPrintRoute }">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>

<app-notification></app-notification>

<app-confirmation-dialog></app-confirmation-dialog>

<app-profile-form *ngIf="isUserLoaded"></app-profile-form>
