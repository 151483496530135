/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms'
import { TranslocoModule } from '@ngneat/transloco'
import { AutofocusDirective } from '../../directives/auto-focus.directive'

export interface FormFieldValidation {
  show: boolean
  message: string
}

@Component({
  selector: 'app-form-field',
  standalone: true,
  templateUrl: 'form-field.component.html',
  styleUrls: ['form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    AutofocusDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true,
    },
  ],
})
export class FormFieldComponent implements ControlValueAccessor, OnInit {
  public value: any
  public disabled = false

  @Input() public formControlName!: string

  @Input() public label?: string

  @Input()
  public placeholder = ''

  @Input()
  public hint = ''

  @Input()
  public mask = ''

  @Input()
  public leadingIcon?: string

  @Input()
  public focus = false

  @ViewChild('formField')
  public formField?: ElementRef

  public control?: AbstractControl

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  public ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control =
          this.controlContainer.control?.get(this.formControlName) ?? undefined
      } else {
        console.warn(
          'Missing FormControlName directive from host element of the component',
        )
      }
    } else {
      console.warn("Can't find parent FormGroup directive")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: any = () => {}
  public onChange: any = () => this.value

  public writeValue(value: any): void {
    this.value = value

    if (value === null) {
      this.value = ''
    }

    this.onChange(this.value)
    if (this.formField) this.formField.nativeElement.value = this.value
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  public onInput(event: Event): void {
    const newValue = (event.target as HTMLInputElement).value
    this.value = newValue
    this.onChange(this.value)
    this.onTouched()
  }

  public get isInvalid(): boolean {
    return (this.control?.invalid && this.control?.touched) ?? false
  }

  public get feedbackLabel(): string {
    return this.formControlName + '-feedback-label'
  }
}
