import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'

@Component({
  selector: 'app-shimmer-form-field',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shimmer-form-field.component.html',
  styleUrls: ['./shimmer-form-field.component.scss'],
})
export class ShimmerFormFieldComponent {}
