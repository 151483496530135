/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  forwardRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core'
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms'
import { Subject, takeUntil, tap } from 'rxjs'

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueAccessorDirective),
      multi: true,
    },
  ],
})
export class ValueAccessorDirective<T>
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public control!: FormControl
  private onChange: any
  private onTouched: any
  private valueSubject = new Subject<T>()
  private disabledSubject = new Subject<boolean>()
  public readonly value = this.valueSubject.asObservable()
  public readonly disabled = this.disabledSubject.asObservable()
  private readonly unsubscribe$: Subject<void> = new Subject()

  constructor(@Inject(Injector) private injector: Injector) {}

  public ngOnInit(): void {
    this.setComponentControl()
  }

  public ngOnDestroy(): void {
    this.valueSubject.complete()
    this.disabledSubject.complete()
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public valueChange(v: T): void {
    this.onChange(v)
  }

  public touchedChange(v: boolean): void {
    this.onTouched(v)
  }

  public writeValue(obj: any): void {
    this.valueSubject.next(obj)
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled)
  }

  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl)

    switch (injectedControl.constructor) {
      case NgModel: {
        const { control, update } = injectedControl as NgModel

        this.control = control

        this.control.valueChanges
          .pipe(
            tap((value: T) => update.emit(value)),
            takeUntil(this.unsubscribe$),
          )
          .subscribe()
        break
      }
      case FormControlName: {
        this.control = this.injector
          .get(FormGroupDirective)
          .getControl(injectedControl as FormControlName)
        break
      }
      default: {
        this.control = (injectedControl as FormControlDirective)
          .form as FormControl
        break
      }
    }
  }
}
