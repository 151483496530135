import { Component, Host, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormFieldComponent } from '../form-field/form-field.component'

@Component({
  selector: 'app-form-field-error',
  standalone: true,
  imports: [CommonModule],
  providers: [FormFieldComponent],
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent {
  @Input() public formControlName?: string
  @Input() public message!: string

  constructor(@Host() parent: FormFieldComponent) {
    this.formControlName = parent.formControlName
  }

  public get fieldId(): string {
    return `${this.formControlName ?? 'generic-field'}-invalid-error`
  }
}
