import { ListSchool } from './school.model'
import { ListStudent } from './student.model'
import { UserRole } from './user.model'

export enum TeamMemberStatus {
  ENROLLED = 'Enrolled',
  INVITED = 'Invited',
}

export type TeamMember = {
  id: string
  accountId: string
  crmId?: string
  authId?: string
  firstName: string
  lastName: string
  email: string
  role: UserRole
  avatarColor: string
  avatarImageUrl?: string
  notes: string
  status: TeamMemberStatus
  title: string
  schools: ListSchool[]
  students: ListStudent[]
}

export type NewTeamMember = {
  firstName: string
  lastName: string
  email: string
  role: UserRole
  avatarColor: string
  schoolIds: string[]
  notes: string
}

export type UpdateTeamMember = {
  id: string
  firstName: string
  lastName: string
  email: string
  role: UserRole
  avatarColor: string
  schoolIds: string[]
  notes: string
}

export type ListTeamMember = {
  id: string
  firstName: string
  lastName: string
  role: UserRole
  avatarColor: string
}
