import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

const env = environment.environment

Sentry.init({
  dsn: "https://d1f7aead79b6966024f2eb1a40facd29@o4505720965431296.ingest.us.sentry.io/4507888569876480",
  environment: env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    'https://api.functionalacademics.com',
    'https://api.functionalacademics-dev.com',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
  beforeSend: (event) => {
    if (env === 'local') {
      console.error('SENTRY EVENT: ', event)
      return null
    }
    return event
  },
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
