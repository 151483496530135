import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AnalyticsService } from '@uptechworks/analytics-service-angular'
import jwt_decode from 'jwt-decode'
import { BehaviorSubject } from 'rxjs'
import { Err, Result } from 'ts-results'
import { UserClient } from '../../common/clients/user.client'
import { UserService } from '../../common/services/user.service'
import { AuthSession } from '../models/auth-session.model'
import { SignInCreds } from '../sign-in/sign-in.model'
import { SignUpCreds } from '../sign-up/sign-up.model'
import { AuthClient } from './auth.client'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn$ = new BehaviorSubject(false)

  constructor(
    private authClient: AuthClient,
    private analyticsService: AnalyticsService,
    private userClient: UserClient,
    private userService: UserService,
  ) {
    if (this.getToken()) this.setLoggedIn(true)
  }

  public getAuthHeaders(): HttpHeaders {
    const token = this.getToken()
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    })
  }

  public getToken(): string | undefined | null {
    try {
      return localStorage.getItem('accessToken')
    } catch {
      return
    }
  }

  public async signUp(creds: SignUpCreds): Promise<Result<string, Error>> {
    const signUpResult = await this.authClient.signUp(creds)

    if (signUpResult.err) return signUpResult

    const signInResult = await this.signIn(creds)

    if (signInResult.err) return signInResult

    const getUserResult = await this.userClient.getCurrentUser()

    if (getUserResult.err) return getUserResult

    const user = getUserResult.safeUnwrap()

    this.userService.setCurrentUser(user)

    this.analyticsService.track({ name: 'invitation_accepted' })

    this.setLoggedIn(true)

    return signUpResult
  }

  public async signIn(creds: SignInCreds): Promise<Result<AuthSession, Error>> {
    const result = await this.authClient.signIn(creds)

    if (result.err) return result

    this.setTokens(result.safeUnwrap())

    const getUserResult = await this.userClient.getCurrentUser()

    if (getUserResult.err) return getUserResult

    const user = getUserResult.safeUnwrap()

    this.userService.setCurrentUser(user)

    this.analyticsService.track({ name: 'user_signed_in' })

    this.setLoggedIn(true)

    return result
  }

  public async refreshToken(): Promise<Result<AuthSession, Error>> {
    const refreshToken = localStorage.getItem('refreshToken')
    const idToken = localStorage.getItem('idToken')

    if (!refreshToken || !idToken) {
      return Promise.resolve(Err(new Error('No refresh token found')))
    }

    const decodedToken: { email: string } = jwt_decode(idToken)

    if (decodedToken && !decodedToken?.email)
      return Err(new Error('No email found in token'))

    const refreshTokenCreds = {
      email: decodedToken.email,
      refreshToken,
    }

    const result = await this.authClient.refreshToken(refreshTokenCreds)

    if (result.err) return result

    this.setTokens(result.safeUnwrap())

    this.setLoggedIn(true)

    return result
  }

  public signOut(): Result<void, Error> {
    this.analyticsService.track({ name: 'user_signed_out' })
    this.analyticsService.unsetCurrentUser()

    return Result.wrap(() => {
      this.clearTokens()
      this.setLoggedIn(false)
    })
  }

  private setLoggedIn(loggedIn: boolean): void {
    this.isLoggedIn$.next(loggedIn)
  }

  private setTokens(authSession: AuthSession): void {
    const { idToken, accessToken, refreshToken } = authSession

    localStorage.setItem('idToken', idToken)
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
  }

  private clearTokens(): void {
    localStorage.removeItem('idToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}
