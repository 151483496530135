import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, firstValueFrom, map, switchMap } from 'rxjs'
import { Ok, Result } from 'ts-results'
import { environment } from '../../../environments/environment'
import { UpdateUser, User } from '../../models/user.model'
import { handleBasicHttpError } from '../functional-libraries/utils.library'
import { HttpHelperService } from '../services/http-helper.service'

@Injectable({ providedIn: 'root' })
export class UserClient {
  constructor(
    private http: HttpClient,
    private httpHelperService: HttpHelperService,
  ) {}

  public getCurrentUser(): Promise<Result<User, Error>> {
    const result = this.http
      .get<User>(`${environment.apiUrl}/users`, {
        headers: this.httpHelperService.getAuthHeaders(),
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<User>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public update(user: UpdateUser): Promise<Result<User, Error>> {
    const result = this.http
      .put<User>(`${environment.apiUrl}/users`, user, {
        headers: this.httpHelperService.getAuthHeaders(),
      })
      .pipe(
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<User>(error),
        ),
      )

    return firstValueFrom(result)
  }

  public uploadAvatar(file: File): Promise<Result<void, Error>> {
    const result = this.http
      .post(
        `${environment.apiUrl}/files`,
        {
          name: file.name,
          mimeType: file.type,
        },
        {
          headers: this.httpHelperService.getAuthHeaders(),
          responseType: 'text',
        },
      )
      .pipe(
        switchMap((uploadUrl) => {
          const headers = new HttpHeaders({
            'Content-Type': file.type,
          })
          return this.http.put<void>(uploadUrl, file, { headers })
        }),
        map((response) => Ok(response)),
        catchError((error: HttpErrorResponse) =>
          handleBasicHttpError<void>(error),
        ),
      )

    return firstValueFrom(result)
  }
}
