import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { AuthService } from '../../../auth/services/auth.service'
import { ClickOutsideDirective } from '../../../directives/click-outside.directive'
import { User, UserRole } from '../../../models/user.model'
import { ProfileService } from '../../../profile/services/profile.service'
import { UserService } from '../../services/user.service'
import { AvatarComponent } from '../avatar/avatar.component'
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component'
import { AnalyticsService } from '@uptechworks/analytics-service-angular'

@Component({
  selector: 'app-navbar-main',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MobileMenuComponent,
    ClickOutsideDirective,
    AvatarComponent,
    TranslocoModule,
  ],
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarMainComponent {
  public currentUser?: User
  public showMobileMenu = false
  public showProfileMenu = false

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    public analyticsService: AnalyticsService,
  ) {
    this.userService.currentUser$.subscribe((user) => {
      this.currentUser = user
    })
  }

  public UserRole = UserRole

  public toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu
  }

  public toggleProfileMenu(): void {
    this.showProfileMenu = !this.showProfileMenu
  }

  public closeProfileMenu(): void {
    this.showProfileMenu = false
  }

  public goToHomeRoute(): void {
    this.userService.goToHomeRoute()
  }

  public signOut(): void {
    const result = this.authService.signOut()

    if (result.err) return

    this.router.navigate(['/sign-in'])
  }

  public openProfileForm(): void {
    this.profileService.openProfileForm()
  }

  public hasRole(role: UserRole[]): boolean {
    return this.userService.hasRole(role)
  }
}
