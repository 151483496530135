import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { Observable, Subscription, map, takeWhile, timer } from 'rxjs'
import { ConfirmationDialog } from './confirmation-dialog.model'
import { ConfirmationDialogService } from './confirmation-dialog.service'
import { ClickOutsideDirective } from '../../../directives/click-outside.directive'

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnDestroy {
  @Output()
  public response = new EventEmitter<boolean>()

  public show = false
  public icon!: string
  public iconClass?: string
  public title!: string
  public message!: string
  public confirmButtonText!: string
  public cancelButtonText?: string
  public timeRemaining?: number
  public callback?: (response: boolean) => void
  public timeRemaining$?: Observable<number>
  public canCloseSelf?: boolean

  private subscription: Subscription

  constructor(private confirmationDialogService: ConfirmationDialogService) {
    this.subscription = confirmationDialogService
      .getObservable()
      .subscribe((confirmation) => this.toggleDialog(confirmation))
  }

  public toggleDialog(confirmation: ConfirmationDialog): void {
    this.show = confirmation.show
    this.icon = confirmation.icon
    this.iconClass = confirmation.iconClass
    this.title = confirmation.title
    this.message = confirmation.message
    this.confirmButtonText = confirmation.confirmButtonText
    this.cancelButtonText = confirmation.cancelButtonText
    this.timeRemaining = confirmation.timeRemaining
    this.callback = confirmation.callback
    this.canCloseSelf = confirmation.canCloseSelf

    if (this.timeRemaining) {
      const timeRemaining = this.timeRemaining - 1

      this.timeRemaining$ = timer(0, 1000).pipe(
        map((n) => (timeRemaining - n) * 1000),
        takeWhile((n) => n >= 0),
      )
    }
  }

  public respond(response: boolean): void {
    this.response.emit(response)

    if (this.callback) this.callback(response)
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public get showCountDown(): boolean {
    return Boolean(this.timeRemaining)
  }

  protected closeSelf(): void {
    if (this.canCloseSelf) this.confirmationDialogService.close()
  }
}
