import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { User, UserRole } from '../../../../models/user.model'
import { ProfileService } from '../../../../profile/services/profile.service'
import { AvatarComponent } from '../../avatar/avatar.component'
import { UserService } from '../../../services/user.service'

@Component({
  selector: 'app-mobile-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, AvatarComponent, TranslocoModule],
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  @Input()
  public show = false

  @Input()
  public currentUser?: User

  @Output()
  public navigated = new EventEmitter<void>()

  @Output()
  public signedOut = new EventEmitter<void>()

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
  ) {}

  public UserRole = UserRole

  public onNavigate(): void {
    this.navigated.emit()
  }

  public onSignOut(): void {
    this.signedOut.emit()
  }

  public openProfileForm(): void {
    this.profileService.openProfileForm()
    this.onNavigate()
  }

  public hasRole(role: UserRole[]): boolean {
    return this.userService.hasRole(role)
  }
}
