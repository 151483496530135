/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, from, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { AuthService } from '../auth/services/auth.service'
import { Router } from '@angular/router'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.clone()

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next)
        }

        return throwError(() => error)
      }),
    )
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn$.value) {
      return from(this.authService.refreshToken()).pipe(
        switchMap(() => {
          const modifiedReq = request.clone({
            headers: request.headers.set(
              'Authorization',
              `Bearer ${this.authService.getToken()}`,
            ),
          })

          return next.handle(modifiedReq)
        }),
        catchError((error) => {
          if (error.status == '403') {
            this.authService.signOut()
            this.router.navigate(['/sign-in'])
          }

          return throwError(() => error)
        }),
      )
    }

    return next.handle(request)
  }
}
