<div class="w-full" *ngIf="!isLoading">
  <label
    *ngIf="label"
    [for]="name"
    class="block text-sm font-headline font-medium leading-6"
    [ngClass]="isInvalid ? 'text-red-500' : 'text-gray-label'"
  >
    {{ label }}
  </label>

  <div class="relative rounded-md shadow-sm" [ngClass]="{ 'mt-2': label }">
    <div
      *ngIf="leadingIcon"
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
    >
      <span class="material-icons text-gray-400">
        {{ leadingIcon }}
      </span>
    </div>

    <input
      [type]="inputType"
      [formControl]="hcd.control"
      [name]="name"
      [id]="name"
      class="form-field"
      [ngClass]="{
        'ring-gray-400 focus:ring-gray-400': !isInvalid,
        'ring-red-500 focus:ring-red-500': isInvalid,
        'pl-10': leadingIcon
      }"
      tabindex="1"
      [attr.aria-invalid]="isInvalid"
      [attr.maxlength]="maxLength"
      [placeholder]="placeholder"
      [autofocus]="focus"
      (blur)="onBlur($event)"
    />

    <div
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    >
      <span *ngIf="isInvalid" class="material-icons text-red-500"> error </span>

      <span *ngIf="isDisabled" class="material-icons text-gray-900">
        lock
      </span>
    </div>
  </div>

  <div class="h-6 pt-1">
    <ng-content></ng-content>
  </div>
</div>

<app-shimmer-form-field *ngIf="isLoading"></app-shimmer-form-field>
