import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core'

const focusableElements = ['input', 'select', 'button', 'a', 'textarea']

@Directive({
  selector: '[autofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  @Input()
  public set autofocus(shouldFocus: boolean) {
    this.shouldFocus = shouldFocus
    this.checkFocus()
  }

  private shouldFocus = true

  constructor(private readonly elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    this.checkFocus()
  }

  private checkFocus(): void {
    if (!this.shouldFocus) {
      return
    }

    const hostElement = <HTMLElement>this.elementRef.nativeElement

    if (!hostElement) {
      return
    }

    if (focusableElements.includes(hostElement.tagName.toLowerCase())) {
      setTimeout(() => hostElement.focus())
    } else if (hostElement?.querySelector) {
      for (const tagName of focusableElements) {
        const childElement = <HTMLInputElement>(
          hostElement.querySelector(tagName)
        )
        if (childElement) {
          setTimeout(() => childElement.focus())
          break
        }
      }
    }
  }
}
