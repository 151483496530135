<div
  class="relative z-10 pointer-events-none"
  aria-labelledby="slide-over-title"
  role="dialog"
  aria-modal="true"
  *ngIf="show"
>
  <!--
  Background backdrop, show/hide based on slide-over state.

  Entering: "ease-in-out duration-500"
    From: "opacity-0"
    To: "opacity-100"
  Leaving: "ease-in-out duration-500"
    From: "opacity-100"
    To: "opacity-0"
-->
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-auto"
  ></div>

  <div class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div
        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
      >
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <div class="pointer-events-auto w-screen max-w-2xl" *transloco="let t">
          <form
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
          >
            <div class="h-0 flex-1 overflow-y-auto">
              <div class="bg-gray-50 px-4 py-6 sm:px-6">
                <div class="flex items-center justify-between">
                  <h2
                    class="text-lg font-headline font-medium leading-6"
                    id="slide-over-title"
                  >
                    {{ t('profile-form.title') }}
                  </h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button
                      type="button"
                      class="relative rounded-md bg-gray-50 focus:outline-none"
                      (click)="cancel()"
                    >
                      <span class="absolute -inset-2.5"></span>
                      <span class="sr-only">Close panel</span>
                      <span class="material-icons text-gray-900"> close </span>
                    </button>
                  </div>
                </div>
                <div class="mt-1">
                  <p class="text-sm font-headline font-medium text-gray-500">
                    {{ t('profile-form.message') }}
                  </p>
                </div>
              </div>

              <!-- AVATAR UPDATE FORM -->
              <div *ngIf="updateAvatarFormVisible" class="h-full w-full p-6">
                <h3 class="font-headline font-medium mb-4">
                  Change Profile Photo
                </h3>

                <div class="flex items-center space-x-6">
                  <app-avatar
                    class="inline-block h-16 w-16 shrink-0"
                    [user]="user"
                  ></app-avatar>

                  <input
                    type="file"
                    class="block text-sm text-slate-500 file:mr-4 file:py-2 file:px-3 file:rounded-md file:border-0 file:cursor-pointer file:text-sm file:font-semibold file:bg-red-primary file:text-white file:shadow-sm hover:file:bg-red-500"
                    (change)="fileChangeEvent($event)"
                  />

                  <button
                    *ngIf="croppedImageUrl"
                    type="button"
                    class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    (click)="removePhoto()"
                  >
                    {{ t('profile-form.remove-photo') }}
                  </button>
                </div>

                <div class="flex w-full justify-center">
                  <div class="w-fit rounded-md my-4">
                    <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="true"
                      [aspectRatio]="1 / 1"
                      [resizeToWidth]="128"
                      [resizeToHeight]="128"
                      format="png"
                      (imageCropped)="imageCropped($event)"
                      (loadImageFailed)="loadImageFailed()"
                      [roundCropper]="true"
                    ></image-cropper>
                  </div>
                </div>

                <div class="flex justify-start">
                  <a
                    [routerLink]=""
                    (click)="hideUpdateAvatarForm()"
                    class="font-medium text-sm text-red-700 flex justify-center items-center cursor-pointer"
                  >
                    <span class="material-icons">arrow_back_ios</span>
                    {{ t('profile-form.enter-verification-code.back') }}
                  </a>
                </div>
              </div>

              <!-- VERIFICATION CODE FORM -->
              <div
                *ngIf="verificationCodeFieldVisible"
                class="flex h-full w-full justify-center items-center"
              >
                <div class="w-2/3">
                  <h1
                    class="font-headline text-3xl font-medium text-gray-900 mb-3"
                  >
                    {{ t('profile-form.enter-verification-code.title') }}
                  </h1>

                  <p class="font-headline text-base text-gray-900 mb-10">
                    {{ t('profile-form.enter-verification-code.description') }}
                  </p>
                  <label
                    for="code"
                    class="block text-sm font-headline font-medium leading-6 mb-3"
                  >
                    {{
                      t(
                        'profile-form.enter-verification-code.verification-code'
                      )
                    }}
                  </label>

                  <code-input
                    #codeInputChild
                    id="code"
                    (codeChanged)="setCode($event)"
                  >
                  </code-input>

                  <div class="flex items-center md:justify-between mt-5">
                    <a
                      [routerLink]=""
                      (click)="hideVerificationCodeField()"
                      class="font-medium text-sm text-red-700 flex justify-center items-center cursor-pointer"
                    >
                      <span class="material-icons">arrow_back_ios</span>
                      {{ t('profile-form.enter-verification-code.back') }}
                    </a>

                    <a
                      [routerLink]=""
                      (click)="changeEmail()"
                      class="font-medium text-sm text-red-700 flex justify-center items-center cursor-pointer"
                    >
                      {{
                        t('profile-form.enter-verification-code.resend-code')
                      }}
                    </a>
                  </div>
                </div>
              </div>

              <!-- MAIN FORM -->
              <div
                *ngIf="
                  !verificationCodeFieldVisible && !updateAvatarFormVisible
                "
                class="flex flex-1 flex-col p-6 space-y-6"
              >
                <label
                  for="avatar"
                  class="text-gray-900 text-sm font-headline font-medium"
                >
                  {{ t('profile-form.photo') }}
                  <div
                    id="avatar"
                    class="inline-flex w-full items-center space-x-4 mt-2"
                  >
                    <span
                      #ignoredInput
                      class="h-12 w-12 shrink-0"
                      [cpIgnoredElements]="[ignoredButton, ignoredInput]"
                      [(cpToggle)]="colorToggle"
                      [(colorPicker)]="newColor"
                      [cpOKButton]="true"
                      cpOKButtonClass="bg-red-primary text-white rounded-md py-1 px-2 font-medium text-base"
                      [cpCancelButton]="true"
                      cpCancelButtonClass="bg-white border border-red-primary text-gray-700 rounded-md py-1 px-2 font-medium text-base"
                      [cpDisableInput]="true"
                      cpAlphaChannel="disabled"
                    >
                      <app-avatar [user]="avatarUser"></app-avatar>
                    </span>
                    <button
                      *ngIf="!croppedImageUrl"
                      type="button"
                      class="bg-white border border-red-primary text-gray-700 rounded-md h-min py-1.5 px-3 text-sm font-medium inline-flex items-center justify-center"
                      (click)="colorToggle = !colorToggle"
                    >
                      Change Color
                    </button>
                    <button
                      #ignoredButton
                      type="button"
                      class="bg-white border border-red-primary text-gray-700 rounded-md h-min py-1.5 px-3 text-sm font-medium inline-flex items-center justify-center"
                      (click)="showUpdateAvatarForm()"
                    >
                      {{ t('profile-form.change-photo-button') }}
                    </button>
                  </div>
                </label>
                <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                  <app-text-input
                    formControlName="firstName"
                    [label]="t('profile-form.first-name')"
                  >
                    <app-form-field-error
                      *ngIf="hasError('firstName', 'required')"
                      [message]="t('common.errors.field-required')"
                    ></app-form-field-error>
                  </app-text-input>
                  <app-text-input
                    formControlName="lastName"
                    [label]="t('profile-form.last-name')"
                  >
                    <app-form-field-error
                      *ngIf="hasError('lastName', 'required')"
                      [message]="t('common.errors.field-required')"
                    ></app-form-field-error>
                  </app-text-input>
                </div>

                <app-text-input
                  class="block mt-6"
                  formControlName="title"
                  [label]="t('profile-form.job-title')"
                >
                </app-text-input>

                <hr class="my-8" />

                <h3
                  class="text-gray-900 text-lg font-headline font-medium my-8"
                >
                  {{ t('profile-form.account-info-subtitle') }}
                </h3>

                <span *ngIf="!changeEmailVisible && user">
                  <p>{{ user.email }}</p>

                  <app-button
                    class="-ml-3"
                    buttonSize="xs"
                    buttonStyle="text"
                    (clicked)="toggleChangeEmail()"
                  >
                    {{ t('profile-form.change-email') }}
                  </app-button>
                </span>

                <ng-container *ngIf="changeEmailVisible">
                  <app-text-input
                    class="block mt-6"
                    formControlName="email"
                    [label]="t('profile-form.email')"
                  >
                    <app-form-field-error
                      *ngIf="hasError('email', 'required')"
                      [message]="t('common.errors.field-required')"
                    ></app-form-field-error>
                  </app-text-input>
                  <span>
                    <app-password-field
                      formControlName="password"
                      label="profile-form.password"
                      [updateOnKeydown]="true"
                    >
                      <app-form-field-error
                        *ngIf="hasError('password', 'required')"
                        [message]="t('common.errors.field-required')"
                      ></app-form-field-error>
                    </app-password-field>
                    <app-button
                      class="-ml-3"
                      buttonSize="xs"
                      buttonStyle="text"
                      (clicked)="toggleChangeEmail()"
                    >
                      {{ t('common.cancel') }}
                    </app-button>
                  </span>
                </ng-container>

                <app-text-input
                  class="block mt-6"
                  formControlName="role"
                  [label]="t('profile-form.role')"
                >
                  <app-button
                    class="-ml-3"
                    buttonSize="xs"
                    buttonStyle="text"
                    (clicked)="toggleRoles()"
                  >
                    {{ t('profile-form.learn-more') }}
                  </app-button>
                </app-text-input>
                <!-- </form> -->
              </div>
            </div>
            <div
              *ngIf="!updateAvatarFormVisible"
              class="flex flex-shrink-0 items-center px-4 py-4 justify-end space-x-3"
            >
              <app-button
                buttonSize="md"
                buttonStyle="secondary"
                (clicked)="cancel()"
              >
                {{ t('common.cancel') }}
              </app-button>

              <app-button
                buttonType="submit"
                buttonSize="md"
                [isDisabled]="form.invalid"
                [isBusy]="submitting"
              >
                {{ t('profile-form.save-button') }}
              </app-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-role-modal
  *ngIf="showRoles"
  (buttonClicked)="toggleRoles()"
></app-role-modal>
